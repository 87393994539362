<script>
export default {
  data() {
    return {
      plans: [
        {
          name: 'Facelabs Home',
          value: 100,
          features: [{ text: 'Cameras ao vivo' }, { text: 'Até 5 usuários' }],
        },
        {
          name: 'Facelabs Basic',
          value: 200,
          features: [{ text: 'Cameras ao vivo' }, { text: 'Até 20 usuários' }, { text: 'Whitelist / Blacklist' }],
        },
        {
          name: 'Facelabs Enterprise',
          value: 300,
          features: [
            { text: 'Cameras ao vivo' },
            { text: 'Até 100 usuários' },
            { text: 'Whitelist / Blacklist' },
            { text: 'Controle de acesso por usuário' },
          ],
        },
      ],
    };
  },
};
</script>

<template>
  <div class="mt-10">
    <h5 class="pb-1">Alterar plano</h5>
    <v-row>
      <v-col v-for="item in plans" :key="item.value" md="3">
        <v-card>
          <v-card-text class="pa-7">
            <h2 class="text-center white--text">{{ item.name }}</h2>

            <ul class="pl-4 mt-7" :style="{ minHeight: '150px' }">
              <li v-for="feature in item.features" :key="feature.text" class="white--text">{{ feature.text }}</li>
            </ul>

            <h4 class="white--text mt-5">Plano Mensal</h4>
            <h5 class="mt-2">R$ {{ item.value }} / mês</h5>

            <div v-visible="item.value !== 100" class="text-center mt-10">
              <v-btn rounded color="primary">Selecionar plano</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
